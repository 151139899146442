<div class="activity-body">
  @if (activitiesFormatStyles.length) {
    <div class="activity-grid">
      @for (item of activitiesFormatStyles; track $index) {
        <div
          [class]="item.style.className + ' activity-item onact96 mutual-opacity'"
          [class.long]="item.longStyle"
          [class.short]="!item.longStyle"
          (click)="click(item.action)"
        >
          <div class="ac-label">
            <span>{{ item.style.text }}</span>
            <span [class]="item.style.secondClassName">{{ item.style.secondText }}</span>
          </div>
          <img [class]="item.style.imageClassName" [src]="item.style.image | preferWebp" alt="" />
          <img
            *ngIf="item.style.imageTwo"
            [class]="item.style.imageTwoClassName"
            [src]="item.style.imageTwo | preferWebp"
            alt=""
          />
        </div>
      }
    </div>
  }
</div>
