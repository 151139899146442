import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { LogoModule } from 'src/app/shared/components/logo/logo.module';
import { NormalCarouselModule } from 'src/app/shared/components/normal-carousel/normal-carousel.module';
import { PipesModule } from 'src/app/shared/pipes/pipes.module';
import { H5TopMenuComponent } from './h5-top-menu.component';

@NgModule({
  imports: [CommonModule, RouterModule, LogoModule, NormalCarouselModule, PipesModule],
  declarations: [H5TopMenuComponent],
  exports: [H5TopMenuComponent],
})
export class H5TopMenuModule {}
