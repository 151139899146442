<div class="bottom-menu" @slideInOut *ngIf="isH5 && !hide && active && (layout.h5BottomMenuHide$ | async) === false">
  @for (item of toolsData; track item.id) {
    <div
      class="menu-item onact96"
      (click)="handleClick(item)"
      [routerLink]="item.page"
      [routerLinkActive]="item.page ? 'active' : ''"
    >
      <span
        class="item-icon {{ item.icon }}"
        [ngStyle]="{
          '--show-dot': (item.id === 2 && hasBonus) || (item.id === 3 && hasNotification) ? 'block' : 'none',
        }"
      ></span>
      <span class="item-txt">{{ item.name }}</span>
    </div>
  }
</div>
