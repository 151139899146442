import { Injectable } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { PopupApi } from '../../apis/popup.api';
import { AfterPopupHideIds, PopupBanner } from '../../interfaces/popup.interface';
import { LocalStorageService } from '../../service/localstorage.service';
import { PopupService } from '../../service/popup.service';
import { BannerPopupComponent } from './banner-popup.component';

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class BannerPopupService {
  constructor(
    private localStorageService: LocalStorageService,
    private popupService: PopupService,
    private popupApi: PopupApi
  ) {}

  onShowPopupForBeforeLogin() {
    this.popupApi
      .getPopupList()
      .pipe(untilDestroyed(this))
      .subscribe(bannerList => {
        let hideIds: number[] = this.localStorageService.getValueByJsonParse('isHideBE') || [];

        // remove expired hideIds
        hideIds = hideIds.reduce((acc, cur) => {
          if (bannerList.find(banner => banner.id === cur)) {
            acc.push(cur);
          }
          return acc;
        }, [] as number[]);
        this.localStorageService.setValueByJsonStringify('isHideBE', hideIds);

        // get banner list without hideIds
        const list = bannerList.reduce((acc, cur) => {
          if (!hideIds.includes(cur.id)) {
            acc.push(cur);
          }
          return acc;
        }, [] as PopupBanner[]);

        if (list.length > 0) {
          this.popupService.open(BannerPopupComponent, {
            speed: 'faster',
            disableClose: true,
            data: {
              bannerList: list,
              closeCallback: () => {},
            },
          });
        }
      });
  }

  onShowPopupForAfterLogin(curUid: string, closeCallback: () => void) {
    this.localStorageService.setValueByJsonStringify('uid', curUid);

    this.popupApi
      .getPopupList()
      .pipe(untilDestroyed(this))
      .subscribe(bannerList => {
        const afterHideIds: AfterPopupHideIds[] = this.localStorageService.getValueByJsonParse('isHideAF') || [];
        let hideIds = afterHideIds.find(item => item.uid === curUid)?.ids || [];

        // remove expired hideIds
        hideIds = hideIds.reduce((acc, cur) => {
          if (bannerList.find(banner => banner.id === cur)) {
            acc.push(cur);
          }
          return acc;
        }, [] as number[]);

        // save hideIds
        const newAfterHideIds = afterHideIds.map(item => {
          if (item.uid === curUid) {
            return {
              uid: curUid,
              ids: hideIds,
            };
          }
          return item;
        });
        this.localStorageService.setValueByJsonStringify('isHideAF', newAfterHideIds);

        // get banner list without hideIds
        const list = bannerList.reduce((acc, cur) => {
          if (!hideIds.includes(cur.id)) {
            acc.push(cur);
          }
          return acc;
        }, [] as PopupBanner[]);

        if (list.length > 0) {
          this.popupService.open(BannerPopupComponent, {
            speed: 'faster',
            disableClose: true,
            data: {
              bannerList: list,
              closeCallback,
            },
          });
        } else {
          closeCallback();
        }
      });
  }
}
